import { TranslateFunction } from 'react-localize-redux';
import { Team } from "../../applications/applicant/models/Team";
import {SelectableItem} from "../models/SelectableItem";
import {Translate} from "./useTranslate";

export type TeamItem = SelectableItem<number>;

export const getTeamItems = (translate: TranslateFunction | Translate, teams: Team[], labelled?: boolean): TeamItem[] =>
    [{ id: null, name: `${(labelled ? translate('TEAM_PREFIX') : '')}${translate('ALL')}` } as TeamItem].concat(
        teams.map<TeamItem>((team) => ({ id: team.id, name: `${(labelled ? translate('TEAM_PREFIX') : '')}${team.name}` }))
    );

export const getExcludedTeamItems = (translate: TranslateFunction | Translate, teams: Team[]): TeamItem[] =>
    [{ id: null, name: translate('NONE') } as TeamItem].concat(
        teams.map<TeamItem>((team) => ({ id: team.id, name: team.name }))
    );