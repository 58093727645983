import React, {FunctionComponent, useContext} from "react";
import {Applicant} from "../models/Applicant";
import {Dropdown} from "react-bootstrap";
import copy from "copy-to-clipboard";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faCommentMedical,
    faCopy,
    faEnvelope, 
    faEye, 
    faEyeSlash,
    faMobileAlt,
    faPaperPlane,
    faPlusSquare, faToggleOff, faToggleOn
} from "@fortawesome/free-solid-svg-icons";
import {Translate} from "react-localize-redux";
import AddCommentAction from "../../../common/components/table-cells/actions/AddCommentAction";
import {isDateOlderThan} from "../../../common/helpers/isDateOlderThan";
import {isValueSet} from "../../../common/helpers/isValueSet";
import DropdownContext from "react-bootstrap/DropdownContext";
import {isObjectEmpty} from "../../../common/helpers/isObjectEmpty";
import {TemplateActionsProps} from "../../../common/components/CommentTemplates/TemplateAction/TemplateAction";
import {MessageType} from "../../../common/models/MessageType";
import {PostCommentActionsProps} from "../../../common/components/CommentTemplates/CommentTemplates";

type ApplicantInfo = Partial<Pick<Applicant, 
    'mobileMarketingConsent' |
    'emailMarketingConsent' |
    'submittedDate' | 'disableNotifications'
>>;
export interface ApplicantActionsMenuProps extends ApplicantInfo, TemplateActionsProps, Pick<PostCommentActionsProps, 'onSubstatusesChange' | 'onDenyApplication'> {
    isApplicationHidden?: boolean;
    onSendSmsClick?: (template?: string) => void;
    onSendEmailClick?: (template?: string) => void;
    onSendSmsAndEmailClick?: (template?: string) => void;
    onCommentSave?: (comment: string) => void;
    onNotificationsChange?: (disable: boolean) => void;
    onCreateNewApplication?: () => void;
    onHideApplication?: () => void;
    onUnhideApplication?: () => void;
}
const ApplicantActionsMenu: FunctionComponent<ApplicantActionsMenuProps> = (props) => {
    const dropdown = useContext(DropdownContext);
    
    const renderCopyMobileNumberAction = () => {
        if (!props.mobileNumber || props.mobileNumber === 'GDPR_GONE') {
            return null;
        }
        return (
            <Dropdown.Item
                key="applicant-actions-copy-mobile-number"
                onClick={() => copy(props.mobileNumber!)}
            >
                <FontAwesomeIcon icon={faCopy}/>
                <Translate id="COPY_MOBILE_NUMBER"/>
            </Dropdown.Item>
        )
    }

    const renderNewApplicationAction = () => {
        if (!props.onCreateNewApplication) {
            return null;
        }
        return (
            <Dropdown.Item
                key="applicant-actions-new-application"
                onClick={() => props.onCreateNewApplication?.()}
            >
                <FontAwesomeIcon icon={faPlusSquare}/>
                <Translate id="NEW_APPLICATION"/>
            </Dropdown.Item>
        )
    }

    const renderAddCommentAction = () => (
        <AddCommentAction
            trigger={(ref, onClick) => (
                <div ref={ref}>
                    <Dropdown.Item
                        key="applicant-actions-add-comment"
                        onClick={e => {
                            onClick(e);
                            /* prevent menu from hiding */
                            e.stopPropagation()
                        }}
                    >
                        <FontAwesomeIcon icon={faCommentMedical}/>
                        <Translate id="ADD_COMMENT" />
                    </Dropdown.Item>
                </div>
            )}
            substatusIds={props.substatusIds}
            email={props.email}
            mobileNumber={props.mobileNumber}
            loanStatus={props.loanStatus}            
            onSave={comment => {
                props.onCommentSave?.(comment);
            }}
            onCommunicationTemplateChange={(template, type) => {
                switch (type) {
                    case MessageType.Email:
                        props.onSendEmailClick?.(template);
                        break;
                    case MessageType.SMS:
                        props.onSendSmsClick?.(template);
                        break;
                    default:
                        props.onSendSmsAndEmailClick?.(template);
                }
            }}
            onSubstatusesChange={props.onSubstatusesChange}
            onDenyApplication={props.onDenyApplication}
        />
    )

    const renderSendSmsAndEmailAction = () => {
        if (!props.email || !props.mobileNumber ||
            props.mobileNumber === 'GDPR_GONE' || props.email === 'GDPR_GONE') {
            return null;
        }
        const mobileMarketingConsent = isValueSet(props.mobileMarketingConsent) ? props.mobileMarketingConsent : true;
        const emailMarketingConsent = isValueSet(props.emailMarketingConsent) ? props.emailMarketingConsent : true;
        const marketingConsent = mobileMarketingConsent && emailMarketingConsent;
        const label = !marketingConsent ?
            <Translate id="REVOKED_MARKETING_CONSENT"/> :
            <Translate id="SEND_EMAIL_AND_SMS"/>;
        return (
            <Dropdown.Item
                key="applicant-actions-send-sms-and-email"
                style={!marketingConsent ? {color: '#c6c6c6'} : undefined}
                onClick={() => props.onSendSmsAndEmailClick?.()}
            >
                <FontAwesomeIcon icon={faPaperPlane}/>
                {label}
            </Dropdown.Item>
        )
    }

    const renderSendSmsAction = () => {
        if (!props.mobileNumber || props.mobileNumber === 'GDPR_GONE') {
            return null;
        }
        const marketingConsent = isValueSet(props.mobileMarketingConsent) ? props.mobileMarketingConsent : true;
        const label = !marketingConsent ?
            <Translate id="REVOKED_MARKETING_CONSENT_SMS"/> :
            <Translate id="SEND_SMS"/>;
        return (
            <Dropdown.Item
                key="applicant-actions-send-sms"
                style={!marketingConsent ? {color: '#c6c6c6'} : undefined}
                onClick={() => props.onSendSmsClick?.()}
            >
                <FontAwesomeIcon icon={faMobileAlt}/>
                {label}
            </Dropdown.Item>
        )
    }

    const renderSendEmailAction = () => {
        if (!props.email ||  props.email === 'GDPR_GONE') {
            return null;
        }
        const marketingConsent = isValueSet(props.emailMarketingConsent) ? props.emailMarketingConsent : true;
        const label = !marketingConsent ?
            <Translate id="REVOKED_MARKETING_CONSENT_EMAIL"/> :
            <Translate id="SEND_EMAIL"/>;
        return (
            <Dropdown.Item
                key="applicant-actions-send-email"
                style={!marketingConsent ? {color: '#c6c6c6'} : undefined}
                onClick={() => props.onSendEmailClick?.()}
            >
                <FontAwesomeIcon icon={faEnvelope}/>
                {label}
            </Dropdown.Item>
        )
    }

    const renderDisableNotificationsAction = () => {
        if (!props.submittedDate || 
            isDateOlderThan(props.submittedDate, 30) ||
            !isValueSet(props.disableNotifications)
        ) {
            return null;
        }
        return (
            <Dropdown.Item
                key="applicant-actions-disable-notifications"
                onClick={() => props.onNotificationsChange?.(!props.disableNotifications)}
            >
                <FontAwesomeIcon icon={props.disableNotifications ? faToggleOn : faToggleOff}/>
                <Translate id={`APPLICANTS_VIEW.${props.disableNotifications ? '' : 'DE'}ACTIVATE_ALERT`}/>
            </Dropdown.Item>
        )
    }
    
    const renderHideApplicationAction = () => {
        if (!isValueSet(props.isApplicationHidden) ||
            !props.onHideApplication ||
            !props.onUnhideApplication
        ) {
            return null;
        }
        return (
            <Dropdown.Item
                key="applicant-actions-hide-application"
                onClick={props.isApplicationHidden ? props.onUnhideApplication : props.onHideApplication}
            >
                <FontAwesomeIcon icon={props.isApplicationHidden ? faEye : faEyeSlash}/>
                <Translate id={props.isApplicationHidden ? 'UNHIDE_APPLICATION' : 'HIDE_APPLICATION'}/>
            </Dropdown.Item>
        )
    }
    
    return (
        // Action menu can be used without parent Dropdown component, therefore we need to show it by default
        <Dropdown.Menu show={isObjectEmpty(dropdown)} renderOnMount className="applicant-actions">
            {renderNewApplicationAction()}
            {renderAddCommentAction()}
            {renderCopyMobileNumberAction()}
            {renderSendSmsAction()}
            {renderSendEmailAction()}
            {renderSendSmsAndEmailAction()}
            {renderDisableNotificationsAction()}
            {renderHideApplicationAction()}
        </Dropdown.Menu>
    )
}
export default ApplicantActionsMenu;