import React, {CSSProperties, FunctionComponent} from 'react';
import { ApplicantsTotal } from '../models/ApplicantsTotal';
import { LoanStatus } from '../../../common/models/LoanStatus';
import { getLoanStatusTranslateKey } from '../../../common/helpers/loanStatusFunctions';
import NumberFormatter from "../../../common/components/NumberFormatter";
import Translate_i18next from "../../../common/components/Translate_i18next";
import {useTranslate_i18next} from "../../../common/useTranslate_i18next";

const statues = [LoanStatus.Paid, LoanStatus.Sent, LoanStatus.Granted];

interface ApplicantsTotalsProps {
    totals: ApplicantsTotal[];
}

const ApplicantsTotals: FunctionComponent<ApplicantsTotalsProps> = ({totals}) => {
    const translate = useTranslate_i18next();
    
    const style: CSSProperties = {
        display: 'flex',
        justifyContent: 'space-evenly',
        fontWeight: 'bold',
        backgroundColor: '#f2f5f7',
        color: '#777777',
        padding: '5px 0',
        borderBottomLeftRadius: '5px',
        borderBottomRightRadius: '5px',
        fontSize: '12px',
        left: '50%',
        transform: 'translateX(-50%)',
        width: '60%',
        position: 'absolute',
        top: '0'
    }

    const renderTotal = (status: LoanStatus) => {
        const filter = status === LoanStatus.Granted
            ? (total: ApplicantsTotal) => total.loanStatus === LoanStatus.Granted || total.loanStatus === LoanStatus.GrantedSelected
            : (total: ApplicantsTotal) => total.loanStatus === status;
        return (
            <div key={status}>
                <span style={{textTransform: 'uppercase'}}><Translate_i18next id="APPLICANT_TOTAL" data={{status: translate(getLoanStatusTranslateKey(status))}}/></span>&nbsp;
                <NumberFormatter value={totals.filter(filter).reduce((p, c) => c.total + p, 0)}/>
            </div>
        );
    }
    
    return (
        <div className="applicants-totals" style={style}>
            {statues.map((s) => renderTotal(s))}
        </div>
    );
};

export default ApplicantsTotals;
