import {faSearch} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React, {FunctionComponent, MouseEvent, useCallback, useState} from 'react';
import Autosuggest, {
    ChangeEvent,
    RenderSuggestionsContainerParams,
    SuggestionsFetchRequestedParams
} from 'react-autosuggest';
import {LocalizeContextProps, Translate, withLocalize} from 'react-localize-redux';
import {connect} from 'react-redux';
import {bindActionCreators, Dispatch} from 'redux';
import {GlobalSearchActionCreators} from '../actions/GlobalSearchActionCreators';
import {ApplicantSearchResult} from '../models/ApplicantSearchResult';
import {ApplicationSearchResult} from '../models/ApplicationSearchResult';
import './autosuggest-theme.css';
import {useNavigate} from "react-router";

interface GlobalSearchReduxProps {
    searchResultData: {
        applicants: ApplicantSearchResult[];
        applications: ApplicationSearchResult[];
    };
}

interface GlobalSearchDispatchProps {
    searchApplicantsAndApplications: (query: string) => void;
}

interface GlobalSearchProps extends GlobalSearchReduxProps, GlobalSearchDispatchProps, LocalizeContextProps {
    openInNewTab?: boolean;
}

interface GlobalSearchState {
    suggestions: any[];
    value: string;
}

const GlobalSearch: FunctionComponent<GlobalSearchProps> = (props) => {
    const navigate = useNavigate();
    const [state, setState] = useState<GlobalSearchState>({suggestions: [], value: ''});
    const [timeoutFn, setTimeoutFn] = useState<NodeJS.Timeout>();

    const searchPlaceHolder = props.translate('HEADER.SEARCH_PLACEHOLDER').toString();

    const inputProps = {
        onChange: (_, params: ChangeEvent) => setState({...state, value: params.newValue}),
        placeholder: searchPlaceHolder,
        value: state.value
    };

    const suggestions: any = [
        {
            title: 'HEADER.CUSTOMERS',
            suggestions: (props.searchResultData && props.searchResultData.applicants) || []
        },
        {
            title: 'HEADER.APPLICATIONS',
            suggestions: (props.searchResultData && props.searchResultData.applications) || []
        }
    ];
    
    const renderSectionTitle = useCallback((section: any) => (<strong><Translate id={section.title} /></strong>), []);

    const getSectionSuggestions = useCallback((section: any) => section.suggestions, []);

    const shouldRenderSuggestions = useCallback((value: string) => value.trim().length > 2, []);

    const onSuggestionSelected = useCallback((data: ApplicantSearchResult & ApplicationSearchResult, openInNewTab?: boolean) => {
        setState((state) => ({ ...state, value: '' }));

        const url = data.applicationId
            ? `/applicant/${data.personId}/application/${data.applicationId}`
            : `/applicant/${data.personId}`;
        
        if (props.openInNewTab || openInNewTab) {
            window.open(url, '_blank');
        } else {
            navigate(url);
        }
    }, [props.openInNewTab, setState, navigate]);

    const onSuggestionsFetchRequested = (request: SuggestionsFetchRequestedParams) => {
        if (request.reason === 'input-changed') {
            if (timeoutFn) {
                clearTimeout(timeoutFn);
            }
            setTimeoutFn(setTimeout(() => props.searchApplicantsAndApplications?.(request.value), 300));
        }
    }

    const renderSuggestionsContainer = useCallback((params: RenderSuggestionsContainerParams) => (
        <div {...params.containerProps}>
            <div className="suggestion-wrapper global-search-wrapper">{params.children}</div>
        </div>
    ), []);
    
    const onSuggestionClick = useCallback((suggestion: ApplicantSearchResult & ApplicationSearchResult, e: MouseEvent<HTMLDivElement>) => {
        if (e.ctrlKey || e.type === 'auxclick') {
            onSuggestionSelected(suggestion, true);
            e.preventDefault();
            e.stopPropagation();
        }
    }, [onSuggestionSelected]);

    const renderSuggestion = useCallback((suggestion: ApplicantSearchResult & ApplicationSearchResult) => {
        let label = `${suggestion.firstName} ${suggestion.lastName}`;
        let description = suggestion.socialSecurityNumber;
        if (suggestion.applicationId) {
            description = label;
            label = `${suggestion.applicationId}`;
        }
        return (
            <div onAuxClick={e => onSuggestionClick(suggestion, e)} onClick={e => onSuggestionClick(suggestion, e)}>
                <div>{label}</div>
                <div><small>{description}</small></div>
            </div>
        );
    }, [onSuggestionClick]);

    const renderInputComponent = useCallback((inputProps: any) => (
        <div className="input-group global-search">
            <div className="input-group-prepend">
                <div className="input-group-text customized">
                    <FontAwesomeIcon icon={faSearch}/>
                </div>
            </div>
            <input {...inputProps} className={inputProps.className + ' form-control customized'}/>
        </div>
    ), []);

    return (
        <Autosuggest
            multiSection={true}
            renderSectionTitle={renderSectionTitle}
            getSectionSuggestions={getSectionSuggestions}
            suggestions={suggestions}
            shouldRenderSuggestions={shouldRenderSuggestions}
            onSuggestionSelected={(_, data) => onSuggestionSelected(data.suggestion)}
            onSuggestionsFetchRequested={onSuggestionsFetchRequested}
            onSuggestionsClearRequested={() => setState((state) => ({ ...state, suggestions: [] }))}
            getSuggestionValue={() => state.value}
            renderSuggestionsContainer={renderSuggestionsContainer}
            renderSuggestion={renderSuggestion}
            renderInputComponent={renderInputComponent}
            inputProps={inputProps}
        />
    );
}

export default connect<GlobalSearchReduxProps, GlobalSearchDispatchProps, {}, any>(
    (state: any) => (state.globalSearchActionReducer),
    (dispatch: Dispatch) => bindActionCreators(GlobalSearchActionCreators, dispatch)
)(withLocalize(GlobalSearch));
