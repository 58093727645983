import React, {useState} from "react";
import "./Bookmarks.css";
import {Dropdown} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBookmark, faPlus, faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import {Translate} from "react-localize-redux";
import Tooltip from "../Tooltip";
import TextInputField from "../input-fields/TextInputField";
import {Storage} from "../../helpers/Storage";

export interface IBookmarks<T> {
    [name: string]: T;
}
interface BookmarksProps<T> {
    common?: IBookmarks<T>;
    storageKey: string;
    active?: T;
    isActive?: (data: T) => boolean;
    onActiveChange?: (data: T) => void;
}
const Bookmarks = <T,>({
    common,
    storageKey,
    active,
    isActive,
    onActiveChange
}: BookmarksProps<T>) => {
    const personalBookmarksStorageKey = `${storageKey}-personal-bookmarks`;
    // TODO: should be removed after some time - created 1st April 2025 - not a joke
    const stored = Storage.getItem(personalBookmarksStorageKey);
    const [personalBookmarks, setPersonalBookmarks] = useState<IBookmarks<T>>(stored ? (typeof stored === 'string' ? JSON.parse(stored) : stored) : {});
    // TODO: should use only line below
    // const [personalBookmarks, setPersonalBookmarks] = useState<IBookmarks<T>>(Storage.getItem(personalBookmarksStorageKey) || {});
    const [addBookmarkName, setAddBookmarkName] = useState<string>();

    const onBookmarkAdd = () => {
        if (!addBookmarkName || !active) {
            return;
        }
        personalBookmarks[addBookmarkName] = active;
        setAddBookmarkName(undefined);
        updatePersonalBookmarks();
    }

    const onBookmarkDelete = (name: string) => {
        delete personalBookmarks[name];
        updatePersonalBookmarks();
    }

    const updatePersonalBookmarks = () => {
        setPersonalBookmarks({...personalBookmarks});
        Storage.setItem(personalBookmarksStorageKey, personalBookmarks);
    }

    const isActiveBookmark = (data: T) => isActive ? isActive(data) : JSON.stringify(data) === JSON.stringify(active);
    
    return (
        <Dropdown drop="down" align="end" className="bookmarks">
            <Dropdown.Toggle className="btn-white-bg" style={{ padding: 0 }}>
                <FontAwesomeIcon icon={faBookmark} />
            </Dropdown.Toggle>
            <div className="dropdown-arrow-down" />
            <Dropdown.Menu>
                {common && !!Object.keys(common).length &&
                    <>
                        <Dropdown.Header key="common-bookmarks">
                            <Translate id="COMMON_BOOKMARKS" />
                        </Dropdown.Header>
                        {Object.entries(common).map(([name, data]) => (
                            <Dropdown.Item key={name} active={isActiveBookmark(data)} onClick={() => onActiveChange?.(data)}>
                                {name}
                            </Dropdown.Item>
                        ))}
                    </>
                }
                {!!Object.keys(personalBookmarks).length &&
                    <>
                        <Dropdown.Divider key="bookmarks-divider" />
                        <Dropdown.Header key="personal-bookmarks">
                            <Translate id="PERSONAL_BOOKMARKS" />
                        </Dropdown.Header>
                        {Object.entries(personalBookmarks).map(([name, data]) => (
                            <Dropdown.Item key={name} active={isActiveBookmark(data)} onClick={() => onActiveChange?.(data)}>
                                {name}
                                <Tooltip content={<Translate id="DELETE_BOOKMARK"/>}>
                                    <FontAwesomeIcon
                                        className={`delete-bookmark-button`}
                                        icon={faTrashAlt}
                                        onClick={(e) => {e.stopPropagation(); onBookmarkDelete(name)}}
                                    />
                                </Tooltip>
                            </Dropdown.Item>
                        ))}
                    </>
                }
                <Dropdown.Divider key="add-bookmark-divider" className="add-bookmark-divider"/>
                <Dropdown.Header key="add-bookmark" className="add-bookmark">
                    <TextInputField
                        name="add-bookmark-name"
                        editMode
                        placeholderKey="TYPE_BOOKMARK_NAME"
                        value={addBookmarkName}
                        onValueChanged={setAddBookmarkName}
                    />
                    <Tooltip content={<Translate id="ADD_BOOKMARK"/>}>
                        <FontAwesomeIcon
                            className={`add-bookmark-button ${!addBookmarkName ? 'disabled' : ''}`}
                            icon={faPlus}
                            onClick={() => onBookmarkAdd()}
                        />
                    </Tooltip>
                </Dropdown.Header>
            </Dropdown.Menu>
        </Dropdown>
    )
}
export default Bookmarks;