import {useCallback, useEffect, useRef} from "react";

const useTabReopenEffect = (onTabReopen: () => void, delay = 15000) => {
    const shouldRunReopenEffect = useRef(false);
    const awaitingTabLeave = useRef(false);
    const timeoutRef = useRef<NodeJS.Timeout | null>(null);

    const startListeningForLeave = () => awaitingTabLeave.current = true;

    const scheduleReopenCallback = useCallback(() => {
        timeoutRef.current = setTimeout(() => {
            shouldRunReopenEffect.current = true;
        }, delay);
    }, [delay]);

    const cancelScheduledReopenCallback = () => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
            timeoutRef.current = null;
        }
    };

    useEffect(() => {
        const handleTabLeave = () => {
            if (awaitingTabLeave.current) {
                awaitingTabLeave.current = false;
                scheduleReopenCallback();
            }
        }
        const handleTabEnter = () => {
            cancelScheduledReopenCallback();
            if (shouldRunReopenEffect.current) {
                shouldRunReopenEffect.current = false;
                onTabReopen();
            }
        }
        const handleVisibilityChange = () => {
            if (document.visibilityState === "visible") {
                handleTabEnter();
            } else {
                handleTabLeave();
            }
        };

        document.addEventListener("visibilitychange", handleVisibilityChange);

        return () => {
            document.removeEventListener("visibilitychange", handleVisibilityChange);
            cancelScheduledReopenCallback();
        };
    }, [onTabReopen, delay, scheduleReopenCallback]);

    return startListeningForLeave;
};

export default useTabReopenEffect;