import React, {FunctionComponent, ReactNode, useCallback, useEffect, useState} from "react";
import "./ApplicationsPipeline.css";
import Pipeline from "../../../../common/components/pipeline/Pipeline";
import Stage, {StageSorter} from "../../../../common/components/pipeline/Stage";
import {LoanStatus} from "../../../../common/models/LoanStatus";
import {bindActionCreators, Dispatch} from "redux";
import {connect} from "react-redux";
import {UserProps} from "../../../../common/interfaces/UserProps";
import {ApplicationPipelineModel} from "../../model/ApplicationPipelineModel";
import {useApplicationsPipelineFilters} from "./helpers/useApplicationsPipelineFilters";
import {getApplications} from "../../api/getApplications";
import {showToastMessage, ShowToastMessageProps} from "../../../../common/actions/ToastMessagesActionCreator";
import RefreshButton from "../../../../common/components/RefreshButton";
import ApplicationsPipelineStage from "./ApplicationsPipelineStage/ApplicationsPipelineStage";
import {useHandleCommandResult} from "../../../../common/helpers/useHandleCommandResult";
import {postComment} from "../../../applicant/api/postComment";
import {putSubstatuses} from "../../../applicant/api/putSubstatuses";
import {putFollowUpReminder} from "../../../applicant/api/putFollowUpReminder";
import {useTranslate} from "../../../../common/helpers/useTranslate";
import {putFollowUpDate} from "../../../applicant/api/putFollowUpDate";
import {Pipeline as PipelineModel} from "../../model/Pipeline";
import {MetadataProps} from "../../../../common/interfaces/MetadataProps";
import ApplicantsViewPicker from "../../../applicants/components/ApplicantsViewPicker/ApplicantsViewPicker";
import {BooleanToggleButtonInputField} from "../../../../common/components/input-fields/BooleanToggleButtonInputField";
import GlobalSearch from "../../../../common/components/GlobalSearch";
import SendEmailAndSmsModal from "../../../../common/components/SendEmailAndSmsModal";
import SendSmsModal from "../../../../common/components/SendSmsModal";
import SendEmailModal from "../../../../common/components/SendEmailModal";
import {PipelineCommunicationConfig} from "../../model/ApplicationPipelineCommunicationConfig";
import {useApplicationsPipelineSorting} from "./helpers/useApplicationsPipelineSorting";
import {isValueSet} from "../../../../common/helpers/isValueSet";
import ConfirmationModal from "../../../../common/components/ConfirmationModal";
import {denyApplication as denyApplicationAPI} from "../../../applicant/api/denyApplication";
import moment from "moment";
import {PipelineStage} from "../../model/PipelineStage";
import ApplicationsPipelineBookmarks from "../ApplicationsPipelineBookmarks/ApplicationsPipelineBookmarks";
import {hideApplication as hideApplicationAPI} from "../../../applicant/api/hideApplication";
import {unhideApplication as unhideApplicationAPI} from "../../../applicant/api/unhideApplication";
import useTabReopenEffect from "../../../../common/helpers/useTabReopenEffect";

type ApplicationsPipelineStateProps = UserProps & MetadataProps;
type ApplicationsPipelineDispatchProps = ShowToastMessageProps;

interface ApplicationsPipelineProps extends ApplicationsPipelineStateProps, ApplicationsPipelineDispatchProps {
    pipeline: PipelineModel;
    onPipelineChange: (name?: string) => void;
    onTeamChange: (team?: number | null) => void;
}
const ApplicationsPipeline: FunctionComponent<ApplicationsPipelineProps> = ({
    pipeline,
    userData,
    metadata,
    showToastMessage,
    onPipelineChange,
    onTeamChange
}) => {
    const translate = useTranslate();
    const handleCommandResult = useHandleCommandResult(showToastMessage);
    const listenForTabLeave = useTabReopenEffect(() => !isLoading && loadApplications());
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [compactView, setCompactView] = useState<boolean>(true);
    const [showHiddenApplications, setShowHiddenApplications] = useState<boolean>(false);
    const [applicationToDeny, setApplicationToDeny] = useState<ApplicationPipelineModel>();
    const [filters, filter, onFilterChange] = useApplicationsPipelineFilters(pipeline, userData, metadata);
    const [getStageSorter, sorting, onSortingChange] = useApplicationsPipelineSorting(pipeline);
    const [sendSmsConfig, setSendSmsConfig] = useState<PipelineCommunicationConfig>();
    const [sendEmailConfig, setSendEmailConfig] = useState<PipelineCommunicationConfig>();
    const [sendSmsAndEmailConfig, setSendSmsAndEmailConfig] = useState<PipelineCommunicationConfig>();
    
    const loadApplications = useCallback(async () => {
        if (!filter) {
            return;
        }
        setIsLoading(true);
        const res = await getApplications(filter);
        pipeline.distributeApplications(res.data);
        setIsLoading(false);
    }, [filter, setIsLoading]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        loadApplications();
    }, [loadApplications]);
    
    useEffect(() => {
        onTeamChange(filter?.teamId);
    }, [onTeamChange, filter?.teamId]);

    const renderStage = useCallback((stage: PipelineStage, i: number, stages: PipelineStage[]) => {
        const sorters = getStageSorter(stage);
        const onSorterChange = (sorter?: StageSorter<ApplicationPipelineModel>) => {
            const sorting = sorter ? {id: sorter.id, order: sorter.order} : undefined;
            onSortingChange({[stage.name]: sorting})
        }
        
        return (
            <Stage<ApplicationPipelineModel>
                key={stage.name}
                data={stage.applications}
                title={(count, sorting) => renderTitle(stage.filter.status, stage.name, count, sorting)}
                isEndStage={stage.isEndStage || i === stages.length - 1}
                render={renderItem}
                summary={() => renderSummary(stage.total)}
                sorters={sorters.filter(sorter => !isValueSet(sorter.visible) || sorter.visible(stage))}
                loadMoreData={canLoadMoreData(stage) ? loadMoreData : undefined}
                onSorterChange={onSorterChange}
            />
        )
    }, [getStageSorter]); // eslint-disable-line react-hooks/exhaustive-deps

    const createNewApplication = (personId: number, applicationId: number) => window.open(`/applicant/${personId}/application/${applicationId}/copy`, '_blank');

    const saveApplicationComment = async (personId: number, applicationId: number, comment: string) => {
        const result = await postComment(personId, applicationId, comment);
        handleCommandResult(result, 'SAVE_COMMENT_SUCCESS');
    }

    const updateSubstatuses = async (personId: number, applicationId: number, ids: number[]) => {
        const result = await putSubstatuses(personId, applicationId, ids);
        handleCommandResult(result, 'UPDATE_SUBSTATUSES');
        if (result.success) {
            loadApplications();
        }
    }

    const denyApplication = async (application: ApplicationPipelineModel) => {
        const result = await denyApplicationAPI(application.personId, application.applicationId);
        handleCommandResult(result, 'DENY_APPLICATION');
        setApplicationToDeny(undefined);
        if (result.success) {
            loadApplications();
        }
    }

    const updateReminder = async (personId: number, applicationId: number, reminder: boolean) => {
        const result = await putFollowUpReminder(personId, applicationId, reminder);
        handleCommandResult(result, 'UPDATE_FOLLOW_UP_REMINDER', translate(reminder ? 'DISABLED_FOLLOW_UP_REMINDER' : 'ENABLED_FOLLOW_UP_REMINDER'))
        if (result.success) {
            loadApplications();
        }
    }

    const updateFollowUp = async (personId: number, applicationId: number, followUp?: string | null) => {
        const result = await putFollowUpDate(personId, applicationId, followUp);
        handleCommandResult(result, 'APPLICANTS_VIEW.FOLLOW_UP_DATE_UPDATE_SUCCESS');
        if (result.success) {
            loadApplications();
        }
    }

    const hideApplication = async (personId: number, applicationId: number) => {
        const result = await hideApplicationAPI(personId, applicationId);
        handleCommandResult(result, 'HIDE_APPLICATION_SUCCESS');
        if (result.success) {
            loadApplications();
        }
    }

    const unhideApplication = async (personId: number, applicationId: number) => {
        const result = await unhideApplicationAPI(personId, applicationId);
        handleCommandResult(result, 'UNHIDE_APPLICATION_SUCCESS');
        if (result.success) {
            loadApplications();
        }
    }
    
    const renderFilters = () => (
        <Pipeline.Filters>
            <GlobalSearch openInNewTab/>
            {filters}
            <div className="local-filters">
                <BooleanToggleButtonInputField
                    key="showHiddenApplications"
                    description={translate('SHOW_HIDDEN')}
                    value={showHiddenApplications}
                    onToggle={setShowHiddenApplications}
                />
                <BooleanToggleButtonInputField
                    key="onlyImportantSubstatuses"
                    description={translate('APPLICATIONS_PIPELINE_VIEW.COMPACT_VIEW')}
                    value={compactView}
                    onToggle={setCompactView}
                />
                <ApplicationsPipelineBookmarks
                    pipeline={pipeline.name}
                    filter={filter}
                    sorting={sorting}
                    onPipelineChange={onPipelineChange}
                    onFilterChange={onFilterChange}
                    onSortingChange={onSortingChange}
                />
            </div>
        </Pipeline.Filters>
    )

    const renderTitle = (status: LoanStatus, label: string, count: number, sorting?: ReactNode) => (
        <ApplicationsPipelineStage.Title
            label={label}
            count={count}
            status={status}
            sorting={sorting}
        />
    );

    const renderItem = (application: ApplicationPipelineModel, isEndStage?: boolean) => (
        <ApplicationsPipelineStage.Item
            key={application.applicationId}
            application={application}
            compactView={compactView}
            differentiateProducts={(filter?.products?.length || 0) > 1}
            onCreateNewApplication={() => createNewApplication(application.personId, application.applicationId)}
            onApplicationOpen={listenForTabLeave}
            onCommentSave={comment => saveApplicationComment(application.personId, application.applicationId, comment)}
            onSubstatusesChange={ids => updateSubstatuses(application.personId, application.applicationId, ids)}
            onDenyApplication={() => setApplicationToDeny(application)}
            onReminderChange={reminder => updateReminder(application.personId, application.applicationId, reminder)}
            onFollowUpChange={followUp => updateFollowUp(application.personId, application.applicationId, followUp)}
            onHideApplication={isEndStage ? () => hideApplication(application.personId, application.applicationId) : undefined}
            onUnhideApplication={isEndStage ? () => unhideApplication(application.personId, application.applicationId) : undefined}
            onSendSmsClick={setSendSmsConfig}
            onSendEmailClick={setSendEmailConfig}
            onSendSmsAndEmailClick={setSendSmsAndEmailConfig}
        />
    );

    const renderSummary = (total: number) => <ApplicationsPipelineStage.Summary total={total}/>;
    
    const loadMoreData = () => {
        onFilterChange({
            closedFrom: moment(filter?.closedFrom).subtract(1, 'week').toISOString()
        })
    }
    
    const canLoadMoreData = (stage: PipelineStage) => (
        stage.isEndStage && 
        !isValueSet(filter?.followUp) &&
        !isLoading &&
        moment().diff(moment(filter?.closedFrom), 'weeks') < 5
    )

    return (
        <div className={`applications-pipeline ${showHiddenApplications ? 'show-hidden-applications' : ''}`}>
            <Pipeline filters={renderFilters()}>
                {pipeline.stages.map(renderStage)}
                <div className="sidebar-buttons">
                    <RefreshButton
                        isLoading={isLoading}
                        onClick={loadApplications}
                    />
                    <hr style={{width: '80%', marginLeft: '10%'}}/>
                    <ApplicantsViewPicker
                        active="pipeline"
                        onChange={active => {
                            if (active !== 'pipeline') {
                                window.location.assign(`/applicants?pageNumber=0&groupBy=${active}`)
                            }
                        }}
                        onFilterChange={filter => onFilterChange({
                            followUp: filter.followUp,
                            followUpDateFrom: filter.followUpDateFrom?.toISOString(),
                            followUpDateTo: filter.followUpDateTo?.toISOString()
                        })}
                    />
                </div>
            </Pipeline>
            {!!sendSmsAndEmailConfig &&
                <SendEmailAndSmsModal
                    show={true}
                    defaultTemplate={sendSmsAndEmailConfig.defaultTemplate}
                    productType={sendSmsAndEmailConfig.product}
                    applicant={sendSmsAndEmailConfig}
                    applicationId={sendSmsAndEmailConfig.applicationId}
                    publicId={sendSmsAndEmailConfig.publicId}
                    onClose={() => setSendSmsAndEmailConfig(undefined)}
                />
            }
            {!!sendSmsConfig &&
                <SendSmsModal
                    show={true}
                    defaultTemplate={sendSmsConfig.defaultTemplate}
                    selectedMobileNumber={sendSmsConfig.mobileNumber!}
                    productType={sendSmsConfig.product}
                    applicant={sendSmsConfig}
                    applicationId={sendSmsConfig.applicationId}
                    publicId={sendSmsConfig.publicId}
                    onClose={() => setSendSmsConfig(undefined)}
                />
            }
            {!!sendEmailConfig &&
                <SendEmailModal
                    show={true}
                    defaultTemplate={sendEmailConfig.defaultTemplate}
                    selectedEmail={sendEmailConfig.email!}
                    productType={sendEmailConfig.product}
                    applicant={sendEmailConfig}
                    applicationId={sendEmailConfig.applicationId}
                    publicId={sendEmailConfig.publicId}
                    onClose={() => setSendEmailConfig(undefined)}
                />
            }
            {!!applicationToDeny &&
                <ConfirmationModal
                    show={true}
                    title={translate('DENY_APPLICATION')}
                    message={translate('DENY_APPLICATION_CONFIRMATION')}
                    onConfirm={() => denyApplication(applicationToDeny)}
                    onCancel={() => setApplicationToDeny(undefined)}
                />
            }
        </div>
    )
}

const mapStateToProps = (state: any) => ({
    ...state.userActionsReducer,
    ...state.metadataActionsReducer
});

const mapActionCreatorsToProps = (dispatch: Dispatch) => bindActionCreators({
    showToastMessage
}, dispatch);

export default connect<ApplicationsPipelineStateProps, ApplicationsPipelineDispatchProps, {}, {}>(mapStateToProps, mapActionCreatorsToProps)(ApplicationsPipeline);