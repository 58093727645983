import React, {useEffect, useMemo, useState} from "react";
import {useTranslate} from "../../../../../common/helpers/useTranslate";
import {ProductType} from "../../../../../common/models/ProductType";
import {FollowUp} from "../../../../../common/models/FollowUp";
import {UserProps} from "../../../../../common/interfaces/UserProps";
import {convertFollowUpToPeriod, mapFollowUpsToSelectableItems} from "../../../../../common/helpers/followUpFunctions";
import GenericDropdown from "../../../../../common/components/GenericDropdown";
import CustomerAdvisorsInputField from "../../../../../common/components/input-fields/CustomerAdvisorsInputField";
import {
    BooleanToggleButtonInputField
} from "../../../../../common/components/input-fields/BooleanToggleButtonInputField";
import {isAdmin} from "../../../../../common/helpers/isAdmin";
import {getTeamItems} from "../../../../../common/helpers/getTeamItems";
import {MetadataProps} from "../../../../../common/interfaces/MetadataProps";
import {isValueSet} from "../../../../../common/helpers/isValueSet";
import DateTimeInputField, {
    DateTimeInputFieldKind
} from "../../../../../common/components/input-fields/DateTimeInputField";
import moment from "moment";
import {Pipeline} from "../../../model/Pipeline";
import {Storage} from "../../../../../common/helpers/Storage";
import {getBankItems} from "../../../../../common/helpers/getBanksItems";


export interface ApplicationsPipelineFilter {
    userIds?: number[] | null;
    teamId?: number | null;
    products?: ProductType[] | null;
    bankId?: number | null;
    followUpDateFrom?: string | null;
    followUpDateTo?: string | null;
    followUp?: FollowUp | null;
    onlyReminders?: boolean | null;
    closedFrom?: string;
}
export const useApplicationsPipelineFilters = (
    pipeline: Pipeline,
    userData: UserProps['userData'],
    metadata: MetadataProps['metadata']
) => {
    const translate = useTranslate();
    const [active, setActive] = useState<ApplicationsPipelineFilter>();
    const storageKey = useMemo(() => `${pipeline.name}.filter`, [pipeline.name]);

    useEffect(() => {
        if (active) {
            if (Object.keys(active).length) {
                Storage.setItem<ApplicationsPipelineFilter>(storageKey, active);
            } else {
                setActive(undefined);
                Storage.removeItem(storageKey);
            }
        }
    }, [active]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setActive({
            ...pipeline.filter,
            userIds: userData.user?.id ? [userData.user?.id] : undefined,
            teamId: userData.user?.teamId,
            bankId: null,
            followUp: null,
            onlyReminders: false,
            ...(Storage.getItem<ApplicationsPipelineFilter>(storageKey) || {}),
            closedFrom: moment().subtract(1, 'week').startOf('day').toISOString()
        });
    }, [pipeline.filter, storageKey]); // eslint-disable-line react-hooks/exhaustive-deps
    
    const onFilterChange = (filter?: Partial<ApplicationsPipelineFilter>) => {
        setActive(prev => ({
            ...prev, 
            closedFrom: moment().subtract(1, 'week').startOf('day').toISOString(),
            ...(filter || {})
        }));
    }
    
    const followUps = mapFollowUpsToSelectableItems(translate);
    const teamsItems = getTeamItems(translate, metadata.teams, true);
    const bankItems = getBankItems(translate, metadata.banks);
    
    const filters = (
        <>
            {isAdmin({userData}) &&
                <GenericDropdown
                    key="teamId"
                    dropdownId="teamId"
                    selectedItem={teamsItems.find((i) => i.id === active?.teamId)}
                    items={teamsItems}
                    onSelectionChanged={i => onFilterChange({teamId: i.id, userIds: []})}
                />
            }
            <CustomerAdvisorsInputField
                key="userId"
                editMode
                placeholderKey="HEADER.FILTER_CUSTOMER_ADVISORS"
                customerAdvisorIds={active?.userIds || []}
                teamScopeId={active?.teamId}
                onCustomerAdvisorsChanged={userIds => onFilterChange({userIds})}
            />
            <GenericDropdown
                key="followUp"
                dropdownId="followUp"
                selectedItem={followUps.find((i) => i.id === active?.followUp)}
                items={followUps}
                onSelectionChanged={i => {
                    const followUp = i.id;
                    let followUpDateFrom: string | undefined = undefined;
                    let followUpDateTo: string | undefined = undefined;
                    if (isValueSet(followUp)) {
                        if (followUp !== FollowUp.Custom) {
                            [
                                followUpDateFrom,
                                followUpDateTo
                            ] = convertFollowUpToPeriod(followUp)
                        }
                    }
                    onFilterChange({
                        followUp,
                        followUpDateFrom,
                        followUpDateTo
                    })
                }}
            />
            <GenericDropdown
                key="bankId"
                dropdownId="bankId"
                selectedItem={bankItems.find((i) => i.id === active?.bankId)}
                items={bankItems}
                onSelectionChanged={i => onFilterChange({bankId: i.id})}
            />
            {active?.followUp === FollowUp.Custom && <>
                <DateTimeInputField
                    name="followUpDateFrom"
                    editMode
                    onValueChanged={from => onFilterChange({followUpDateFrom: moment(from).startOf('day').toISOString()})}
                    value={active?.followUpDateFrom}
                    placeholderKey="FOLLOW_UP_DATE_FROM"
                    kind={DateTimeInputFieldKind.Date}
                />
                <DateTimeInputField
                    name="followUpDateTo"
                    editMode
                    onValueChanged={to => onFilterChange({followUpDateTo: moment(to).endOf('day').toISOString()})}
                    value={active?.followUpDateTo}
                    placeholderKey="FOLLOW_UP_DATE_TO"
                    kind={DateTimeInputFieldKind.Date}
                />
            </>}
            <BooleanToggleButtonInputField
                key="onlyReminders"
                description={translate('ONLY_REMINDERS')}
                value={!!active?.onlyReminders}
                onToggle={onlyReminders => onFilterChange({onlyReminders})}
            />
        </>
    )
    return [filters, active, onFilterChange] as const;
};