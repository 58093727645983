export const Storage = {
    getItem,
    setItem,
    removeItem
};

function getItem<T>(key: string): T | null {
    const jsonString = window.localStorage.getItem(key);
    return jsonString !== null ? JSON.parse(jsonString) : null;
}

function setItem<T>(key: string, item: T): void {
    window.localStorage.setItem(key, JSON.stringify(item));
}

function removeItem(key: string): boolean {
    const existed = window.localStorage.getItem(key) !== null;
    window.localStorage.removeItem(key);
    return existed;
}