import {TranslateFunction} from 'react-localize-redux';
import {SelectableItem} from "../models/SelectableItem";
import {Translate} from "./useTranslate";
import {Bank} from "../../applications/applicant/models/Bank";

export type BankItem = SelectableItem<number>;

export const getBankItems = (translate: TranslateFunction | Translate, banks: Bank[]): BankItem[] =>
    [{ id: null, name: `${translate('BANK_PREFIX')}${translate('ALL')}` } as BankItem].concat(
        banks
            .sort((b1, b2) => b1.name.localeCompare(b2.name))
            .map<BankItem>(bank => ({ id: bank.id, name: `${translate('BANK_PREFIX')}${bank.name}` }))
    );